<template>
  <div class="lists_wrapper">
    <div class="header_title flex">
      <p class="flexs">项目情况：</p>
      <ul class="flext uls">
        <li
          @click="tabActive(index)"
          v-for="(item, index) in tabs"
          :key="index"
          :class="active == index ? 'active' : ''"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="list_flexible">
      <ul>
        <!-- item.webUrl -->

        <li
          class="flex cursorP"
          v-for="item in flexLists"
          :key="item.id"
          @click="goRouter(item.webUrl)"
        >
          <!-- :src="item.imgUrl" -->
          <div class="imgs">
            <img
              src="../../static/login/1.jpg"
              alt=""
              :class="item.imgUrl ? '' : 'err_image'"
            />
          </div>
          <div class="content flex">
            <h2>
              {{ item.name }} 
              <!-- 阿里巴巴淘宝云客服-->
            </h2>
            <p class="p1 line_clamp1">
              <!--阿里巴巴云客服是阿里巴巴集团官方的客户服务渠道，为阿里巴巴平台及生态...-->
                {{ item.describ }}  
            </p>
            <div>
              <p class="p2" v-if="item.hasBatch==1">
                 报名时间：{{ item.batchStartTime }}至{{ item.batchEndTime }}
              </p>
               <p class="p2" v-else>
                  报名时间：{{ item.begenTime }}至{{ item.endTime }}
              </p>
            <!--   <p class="p2">
                 进行时间：2020-03-03至2020-03-04 
                报名周期：每月12日0点——次月10日24点。
              </p>-->
            </div>
            <!-- <p class="p3">
              已报名 <span>{{ item.personNum||'0' }}</span> 人
            </p> -->
          </div>
          <div class="flexs btn ">
            <el-button type="primary">
              <img src="~static/message/icons.png" alt="" />立即报名
            </el-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      tabs: ["全部"],
      // tabs: ["全部", "已开始", "未开始", "已结束"],
      flexLists: [],
    };
  },
  methods: {
    tabActive(val) {
      console.log(val);
      this.active = val;
    },
    async flexFn() {
      let res = await this.$api.queryLhjyActivityList({
        pageSize: 6,
        pageNum: 1,
      });
      this.flexLists = res.data.data.records;
    },
    goRouter(url, a) {
      console.log(url);
      window.open(url, "_blank");
    },
  },
  created() {
    this.flexFn();
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/messages/flexibelList";
</style>
